<template>
  <div class="centered-content">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.centered-content {
  width: 100%;
  display: flex;
  justify-content: center;

  &.vertical {
    align-items: center;
    height: 100%;
    flex-direction: column;
  }
}
</style>
